import React, { useState, useRef } from 'react'
import './App.css';
import './style/styles.css'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { createEventId } from './event-utils'
import './loading-skeleton.js';
import LoadingSkeleton from './loading-skeleton.js';
import TaskPopup from './TaskPopup.js';
import Task from './Task.js';

export default function Cali() {
  const [currentEvents, setCurrentEvents] = useState([])
  const [tasks, setTasks] = useState ([]);
  const calendarRef = useRef(null);
  const generateButton = useRef(null);
  const [initEvents, setInitEvents] = useState (null);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [popupState, setPopupState] = useState (false);

  // Connection to server; defaults to deployed if not configured.
  const serverLink = process.env.REACT_APP_SERVER_LINK || "https://cali-wdgj.onrender.com";

  function handleDateSelect(selectInfo) {
    let title = prompt('Please enter a new title for your event')
    let calendarApi = selectInfo.view.calendar

    calendarApi.unselect() // clear date selection

    if (title) {

      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        description: 'Virginia',
        daysOfWeek: selectInfo.daysOfWeek,
        startRecur: selectInfo.startRecur,
        endRecur: selectInfo.endRecur
      })
    }

  }

  // function handleEvents(events) {
  //   setCurrentEvents(events)
  // }


  function handleFormSubmission(e) {
    e.preventDefault();
    setPopupState(false);

    const data = ([...(new FormData(e.target).entries())]);
    // console.log (...data.entries()); // DEBUGGING!
  
    const cancelled = data[0][1];

    // EARLY EXIT - The user cancelled form submission.
    if (cancelled == "true")
    {
      console.log("User cancelled - closing popup...");
      return;
    }

    // Track changes to tasks list.
    console.log('Storing tasks...');
    let name = document.getElementsByName("name")[0];
    let location = document.getElementsByName("location")[0];
    let hours = document.getElementsByName("hours")[0];

    const hoursValue = Number (hours.value);

    // EARLY EXIT - The hours value is less than 1.
    if (hoursValue < 1)
    {
      alert ("Please enter in a Hour value greater than or equal to 1");
      return;
    }

    let obj = {
      name: name.value,
      location: location.value,
      commit_level: hours.value,
    }

    setTasks ([...tasks, obj]);

    name.value = "";
    location.value = "";
    hours.value = "";
  }


  function addEvents() {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();

      calendarApi.getEvents().forEach((event) => {
        const startDate = new Date(event.startStr);
        const endDate = new Date(event.endStr);

        const ev = {
          id: event.id,
          name: event.title,
          location: event.extendedProps?.description || "",
          start: startDate.toLocaleTimeString("en-US", { hour: "numeric", minute: "2-digit", hour12: true }),
          end: endDate.toLocaleTimeString("en-US", { hour: "numeric", minute: "2-digit", hour12: true }),
          day: startDate.toLocaleDateString("en-US", { weekday: "short" }),
          confirmed: false,
          commit_level: 0,
        };
        currentEvents.push(ev);
      });
    }
  }

  function handleEventClick(clickInfo) {
    if (window.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove()
    }
  }

  return (
    <div className="App" >
      <div className='nav'>Hi, I'm CALI!<img src="./calico.png" alt="cat icon" width="100" style={{ position: 'relative', bottom: '-3px' }}/>
      </div>

      <main>
        <div className='flex-col'>
          <div className='toolbar'>
            <p>
              Hello! Welcome to CALI, a calendar assisting logistical interface! 
              You can use CALI like a typical calendar, but there's much more you
              can do. CALI can take in new tasks and find the optimal time in your
              schedule to fit in the task. Try it out!
            </p> 
            {
              popupState ?
                <TaskPopup handleFormSubmission={handleFormSubmission} />
                : null
            }
            <button className='addTaskButton' onClick={() => { setPopupState(true) }}><b>+</b></button>
          </div>
          <div id="taskDisplay" className='flex-col'>
            <h2>Tasks</h2>
            {
              tasks.length > 0 ?
                tasks.map ((task, index) => {
                  return <Task key={index} name={task.name} location={task.location} hours={task.commit_level} />
                })
              : <Task empty={true} />
            }
          </div>

        </div>

        <div className='calendar'>
          <FullCalendar
            ref={calendarRef} // Assign reference here
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView='timeGridWeek'

            editable={true}
            selectable={true}

            select={handleDateSelect}
            eventContent={renderEventContent}
            eventClick={handleEventClick}
            // eventsSet={handleEvents}
          />
        </div>
      </main>

      <div>
        <button id='generate' type='generate' ref={generateButton} onClick={generateSchedule}>Generate Optimized Schedule</button>
      </div>
      { loadingStatus ? 
        <LoadingSkeleton />
        : null
      }
    </div >
  );




  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    )
  }



  function generateSchedule() {
    // { id, events: [...{ name, location, start_time, end_time, days }], tasks: [{ name, location, commit_level }]

    // create the json
    console.log("button clicked")
    addEvents();

    const json = {
      id: 1001,
      events: currentEvents,
      // tasks: [{ name: 'go for run', location: 'deep run', commit_level: '5' }],
      tasks: tasks
    }

    console.log (json); // DEBUGGING!
  
    // Show a loading screen while fetching.
    setLoadingStatus(true);

    // make the request
    fetch (`${serverLink}/app/generate`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify ({ data: json })
    })
    .then (async (resp) => {

      // Hide loading screen.
      setLoadingStatus(false);

      console.log ('Server responded with:');
      const response = await resp.json ();
      console.log (response); // DEBUGGING!

      setCurrentEvents ([...currentEvents, ...response.content]);

      // Set the new events
      const INIT_EVENTS = [];

      currentEvents.forEach((event) => {
        const newEvent = {
          title: event.title,
          start:event.startStr,
          end: event.endStr,
          day: event.day
        }

        INIT_EVENTS.push(newEvent);
      })

      setCurrentEvents(INIT_EVENTS);
      // TODO!

    })
    .catch ((err) => {
      console.error (`Error: Server responded with ${err}`);
    });
  }
}
