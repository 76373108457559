import './App.css';
import './style/styles.css';

/**
 * The task creation form, which is styled as a pop-up window to users.
 * 
 * @param {function} formSubmitHandler Callback fn to use when the form submits.
 */
function TaskPopup ({ handleFormSubmission })
{
    return (
    <div className='fullscreen blackout absolute popup'>
        <form className='form relative' onSubmit={handleFormSubmission}>
            <button
                type="submit"
                className="cancelButton"
                onClick={(e) => {
                    // console.log (document.getElementById('cancelValue').value); // DEBUGGING!
                    document.getElementById('cancelValue').value = true;
                }}
            ><b>X</b></button>
            <h2 className='header'>Add Tasks</h2>
            <input id="cancelValue" type="hidden" name="cancelValue" value={false}></input>
            <label className="tasklabel" for="name">Name</label><br></br>
            <input type='text' name='name' placeholder='Enter Task Name'></input>
            <label className="tasklabel" for="location">Location</label><br></br>
            <input type='text' name='location' placeholder='Enter Location'></input>
            <label className="tasklabel" for="hours">Hour(s)</label><br></br>
            <input type='number' name='hours' placeholder='Enter Time you want to spend'></input>
            <button type='submit' style={{ padding: '3% 4%', borderRadius: '10px', fontSize: '1.1rem', fontWeight: 'bold' }}>Submit</button>
        </form>
    </div>
    );
}

export default TaskPopup;