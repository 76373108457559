import './App.css';

// Displays a full-page loading screen to the user while the AI forms a response.
function LoadingSkeleton()
{
    return (
        <div className='blackout fullscreen absolute flex' style={{ zIndex: '99' }}>
            <main className='animation-box relative'>
                <div className='loading-dot' style={{ top: '20%', left: '48%' }}></div>
            </main>
        </div>
    );
}

export default LoadingSkeleton;