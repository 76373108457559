import './App.css';
import './style/styles.css';

/**
 * Renders a Task within the CALI program.
 * 
 * @param {String} name The name of the Task to display.
 * @param {String} location The location of the Task.
 * @param {Number} hours The amount of time spent weekly on a Task.
 * @param {Boolean} empty Flag to set to true when the Task rendered should be a default empty Task.
 */
function Task ({ name=null, location=null, hours=0, empty=false })
{

    if (empty)
    {
        return (
            <div id="default-task-box" className="task-box">
                <p>Empty</p>
                <p>Add some tasks above!</p>
            </div>
        );
    } else if (name == null || location == null || hours <= 0)
    {
        throw new Error ("Error: Specify name, location, and hour values for non-empty tasks");
    }

    return (
        <div className="flex-col flex-center task-box">
            <p>{name}</p>
            <aside className='task-info'>
                <div>Place: <b>{location}</b></div>
                <div>Hours: <i>{hours}</i></div>
            </aside>
        </div>
    );

}

export default Task;